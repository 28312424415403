







import Vue from 'vue';
import Home from './components/Home.vue';

export default Vue.extend({
    name: 'App',
    components: {
        Home
    }
});


































import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
    name: 'Home',
    data() {
        return {
            items: [],
            hometown: {}
        }
    },
    mounted() {
        axios
            .get("/api")
            .then(response => (
                this.items = response.data.critical,
                this.hometown = response.data.hometown
            ))
    }
});

